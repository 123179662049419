<template>

  <quick-quote-page
    id="Projects"
    :optionsname="$options.name"
  >
    <template #keyselection>
      <select-customer
        v-if="customers.data.length > 1"
        id="2-maintain"
        v-model="customers.selectedItem"
        :appendhint="`to view its ${displayAsPlural}`"
      />
    </template>

    <template #keyactions>
      <v-col
        cols="12"
        sm="7"
      >
        <v-btn
          small
          color="primary"
          class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
          @click="menuNew_onClick"
        >
          New {{ displayAsSingular }}
        </v-btn>
      </v-col>
    </template>

    <template #content>
      <v-overlay :value="maintenanceState.isBusy">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        />
      </v-overlay>
    </template>
  </quick-quote-page>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import QuickQuotePage from '@/components/QuickQuotePage'
  import SelectCustomer from '@/components/keys/SelectCustomer'

  export default {
    name: 'ProjectsView',

    components: {
      QuickQuotePage,
      SelectCustomer,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
        'getDataById',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsSingular: get('project/displayAsSingular'),
      displayAsPlural: get('project/displayAsPlural'),
      maintenanceState: get('project/projects'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$store.dispatch('project/retrieveAll')
      },

      menuNew_onClick () {
        // TODO: Add new project
      },
    },
  }
</script>
